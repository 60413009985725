// 连接服务端接口 正式地址以及一些测试地址
// const wsurl = 'ws://47.98.181.27:8888';
// import { env_config } from "./environment"
// let environment = env_config;//1、本地 2、开发wingstiger 3、海泰

import { sever_config } from "@/static/global/sever_config";

let environment = sever_config;//1、本地 2、测试环境wingstiger 3、海泰(正式环境) 4：开发环境(rslaser)

let serverurl = "";
let wsurl = '';
switch (environment) {
  case 1:
    serverurl = "http://192.168.124.17:8099/etiger";
    wsurl = 'wss://im.wingstiger.com';
    break;
  case 2:
    serverurl = 'https://elis.wingstiger.com/etiger';
    wsurl = 'wss://im.wingstiger.com';//:8899
    break;
  case 3:
    serverurl = 'https://www.elis-medical.com/etiger';
    wsurl = 'wss://im.wingstiger.com';
    break;
  case 4:
    serverurl = "https://elis.rslaser.com/etiger";
    wsurl = 'wss://im.wingstiger.com';
}

const uploadPicurl = serverurl + '/picture/uploadFile';
// 全局资源管理配置
import globalConfig from "./globalConfig.js";
let { platFrom, platFromList, platFromList0, globalConfigAll, businessBelong, knowledgeTypeList,actSource,videoPlayerHttp } = globalConfig;
// 高德地图Key以及安全秘钥
import { gaode_map_config } from "../gaode_map_config/gaode_map_config";
// console.log(gaode_map_config);
export default {
  actSource,
  serverurl,
  uploadPicurl,
  wsurl,
  platFrom,
  platFromList,
  platFromList0,
  globalConfigAll,
  businessBelong,
  gaode_map_config,
  knowledgeTypeList,
  videoPlayerHttp
};
