/*
莅视医疗平台----pc
----服务器接口连接地址
1、本地 2、测试wingstiger 3、海泰(正式环境) 4：开发环境(rslaser 测试)
*/
let sever_config = 3;

/*
----公司业务分平台
1、海泰新光 2、国药新光
*/
let web_act_source = 1;


/*
----配置资源项 (logo和登录页二维码）
1、wingstiger 2、eliscn 3、海泰新光  4、国药新光
新配置资源项 1、wingstiger 2、eliscn 3、海泰新光（正式环境）  4、国药新光 5 开发环境（rslaser）
*/
let static_config = 3;

let mova_config = {
    env: 3,//1、安卓 2、ios 3、web
}

// ### 视频播放地址 1 本地17 2翼虎会议（测试环境） 3海泰正式环境 4开发环境（rslaser）
let video_config = 3

export {
    sever_config,
    web_act_source,
    mova_config,
    static_config,
    video_config
}
