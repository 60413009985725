let global_static = function () {
    this.getRoleCodeList = () => {
       /* 新角色 角色类型: 0: 超级管理员（内部用 可以创建管理角色10），1: 手术医生（莅视），2: 专家医生（莅视） 3:业务员（莅视） 4:未实名认证 5: 其他认证 6:认证医生（创新专家医生） 7手术医生未认证
         9管理员(创新）10.系统管理员 11.镜检业务员/病理业务员*/
        let list = [
            {
                roleCode: 0,
                roleCodeName: "超级管理员",
            },
            {
                roleCode: 1,
                roleCodeName: "医师",// 手术医生
            },
            {
                roleCode: 2,
                roleCodeName: "专家",
            },
            {
                roleCode: 3,
                roleCodeName: "业务员",
            },
            {
                roleCode: 4,
                roleCodeName: "未实名认证",
            },
            {
                roleCode: 5,
                roleCodeName: "其他认证",
            },
            {
                roleCode: 6,
                roleCodeName: "认证医生",
            },
            {
                roleCode: 7,
                roleCodeName: "医师未认证",
            //     手术医生未认证
            },
            {
                roleCode: 9,
                roleCodeName: "管理员",
            },
            {
                roleCode: 10,
                roleCodeName: "系统管理员",
            },
            {
                roleCode: 11,
                roleCodeName: "镜检业务员",
            },
        ];
        return list;
    }
}
export default global_static;