/*
模块名：莅视pc系统全局切换不同环境配置项
模块说明：通过修改platFrom elis_type来切换不同环境
copyright：杭州翼虎计算机科技有限公司 版权所有
作者：宋博
最后修改时间：2022年11月23日
*/
// import { static_config } from "./environment";
// console.log("static_config", static_config)

import {static_config, video_config} from "@/static/global/sever_config";

import {web_act_source} from "@/static/global/sever_config";

// actSource 来源配置项 更改actSource变量即可
let actSource = web_act_source;// 1、海泰新光 2、国药新光

let platFrom = () => {
    // 注册来源 1、海泰新光 2、国药新光
    let source = actSource
    // let source = static_config == 4 ? 2 : 1;
    let commonParams = {
        platform: 'pc',
        requestNo: 10001,
        requestCode: 20001,
        actSource: source,
    };
    return commonParams;
};
let businessBelong = (roleCode) => {
    let list = [];
    if (roleCode == 10) {
        list = [
            {
                name: "莅视中心",
                id: 2,
            },
            {
                name: "创新联盟",
                id: 3,
            }
        ];
    } else {
        list = [
            {
                name: "莅视和创新",
                id: 1,
            },
            {
                name: "莅视中心",
                id: 2,
            },
            {
                name: "创新联盟",
                id: 3,
            }
        ];
    }

    return list
}
let elis_type = static_config;// 新配置资源项 1、wingstiger 2、eliscn 3、海泰新光  4、测试 5、rslaser开发环境
// 旧配置资源项 1、wingstiger 2、eliscn 3、海泰新光  4、国药新光
let globalConfigAll = {};
let platFromList = [
    { label: '全部平台', value: 0 },
    { label: '莅视网', value: 1 },
    { label: '国药新光', value: 2 },
];
let platFromList0 = [
    // { label: '全部平台', value: 0 },
    { label: '莅视网', value: 1 },
    { label: '国药新光', value: 2 },
];
let globalConfigAll_wings = {
    // wingstiger
    textLogo: "莅视医疗平台",// 登录页面欢迎文字
    loginBc: "https://etiger1101.oss-cn-hangzhou.aliyuncs.com/%E5%89%8D%E7%AB%AFelis/pic_enter_left.png",// 登录页面封面图片
    logoUrl: "https://elismed.oss-cn-beijing.aliyuncs.com/elis_web/logo_elis.png",// logo 图片
    mobileUrl: "https://elismed.oss-cn-beijing.aliyuncs.com/downloads/wings_tiger.jpg",// app下载地址图片二维码
    ICP: {
        text1: "本网站由杭州翼虎计算机科技有限公司提供技术支持   |   备案号：",
        text3: "浙ICP备2022007941号-1",
        link: "https://beian.miit.gov.cn/#/Integrated/index",
        text2: "（点击ICP跳转地址）",
        text4: "鲁公网安备37021202001592",
        link4: "https://beian.mps.gov.cn/#/query/webSearch",
        icon_filing: "https://elismed.oss-cn-beijing.aliyuncs.com/web_mova/icon_filing.png"
    }
};
let globalConfigAll_eliscn = {
    // elismed.cn
    textLogo: "莅视医疗平台",// 登录页面欢迎文字
    loginBc: "https://etiger1101.oss-cn-hangzhou.aliyuncs.com/%E5%89%8D%E7%AB%AFelis/pic_enter_left.png",// 登录页面封面图片
    logoUrl: "https://elismed.oss-cn-beijing.aliyuncs.com/elis_web/logo_elis.png",// logo 图片
    mobileUrl: "https://elismed.oss-cn-beijing.aliyuncs.com/downloads/wings_tiger.jpg",// app下载地址图片二维码
    ICP: {
        text1: "本网站由杭州翼虎计算机科技有限公司提供技术支持   |   备案号：",
        text3: "浙ICP备2022011571号-1",
        link: "https://beian.miit.gov.cn/#/Integrated/index",
        text2: "（点击ICP跳转地址）",
        text4: "鲁公网安备37021202001592",
        link4: "https://beian.mps.gov.cn/#/query/webSearch",
        icon_filing: "https://elismed.oss-cn-beijing.aliyuncs.com/web_mova/icon_filing.png"
    }
};
let globalConfigAll_elis_medical = {
    // 海泰新光
    textLogo: "莅视医疗平台",// 登录页面欢迎文字
    loginBc: "https://etiger1101.oss-cn-hangzhou.aliyuncs.com/%E5%89%8D%E7%AB%AFelis/pic_enter_left.png",// 登录页面封面图片
    logoUrl: "https://elismed.oss-cn-beijing.aliyuncs.com/elis_web/logo_elis.png",// logo 图片
    mobileUrl: "https://elismed.oss-cn-beijing.aliyuncs.com/downloads/elismed.jpg",// app下载地址图片二维码
    ICP: {
        text1: "本网站由杭州翼虎计算机科技有限公司提供技术支持   |   备案号：",
        text3: "鲁ICP备19055674号-6",
        link: "https://beian.miit.gov.cn/#/Integrated/index",
        text2: "（点击ICP跳转地址）",
        text4: "鲁公网安备37021202001592",
        link4: "https://beian.mps.gov.cn/#/query/webSearch",
        icon_filing: "https://elismed.oss-cn-beijing.aliyuncs.com/web_mova/icon_filing.png"
    }
};
let globalConfigAll_guoyao = {
    // 国药新光
    textLogo: "国药新光数字化外科诊疗交互平台",// 登录页面欢迎文字
    loginBc: "https://elismed.oss-cn-beijing.aliyuncs.com/elis_web/bc_guoyao.png",// 登录页面封面图片
    logoUrl: "https://elismed.oss-cn-beijing.aliyuncs.com/elis_web/logo_guoyao.png",// logo 图片
    mobileUrl: "https://elismed.oss-cn-beijing.aliyuncs.com/downloads/gyxg.png",// app下载地址图片二维码
    ICP: {
        text1: "本网站由杭州翼虎计算机科技有限公司提供技术支持   |   备案号：",
        text3: "京ICP备20220251156号-2",
        link: "https://beian.miit.gov.cn/#/Integrated/index",
        text2: "（点击ICP跳转地址）",
        text4: "鲁公网安备37021202001592",
        link4: "https://beian.mps.gov.cn/#/query/webSearch",
        icon_filing: "https://elismed.oss-cn-beijing.aliyuncs.com/web_mova/icon_filing.png"
    }
};
let globalConfigAll_elis_rslaser = {
    // rslaser
    textLogo: "莅视医疗平台",// 登录页面欢迎文字
    loginBc: "https://etiger1101.oss-cn-hangzhou.aliyuncs.com/%E5%89%8D%E7%AB%AFelis/pic_enter_left.png",// 登录页面封面图片
    logoUrl: "https://elismed.oss-cn-beijing.aliyuncs.com/elis_web/logo_elis.png",// logo 图片
    mobileUrl: "https://elismed.oss-cn-beijing.aliyuncs.com/downloads/rslaser.png",// app下载地址图片二维码
    ICP: {
        text1: "本网站由杭州翼虎计算机科技有限公司提供技术支持   |   备案号：",
        text3: "浙ICP备2022011571号-1",
        link: "https://beian.miit.gov.cn/#/Integrated/index",
        text2: "（点击ICP跳转地址）",
        text4: "鲁公网安备37021202001592",
        link4: "https://beian.mps.gov.cn/#/query/webSearch",
        icon_filing: "https://elismed.oss-cn-beijing.aliyuncs.com/web_mova/icon_filing.png"
    }
};

if (elis_type == 1) {
    console.log("莅视_wingstiger");
    globalConfigAll = globalConfigAll_wings
} else if (elis_type == 2) {
    console.log("莅视_elis.cn")
    globalConfigAll = globalConfigAll_eliscn
} else if (elis_type == 3) {
    console.log("莅视_海泰新光")
    globalConfigAll = globalConfigAll_elis_medical
} else if (elis_type == 4) {
    console.log("莅视_国药新光")
    globalConfigAll = globalConfigAll_guoyao
} else if(elis_type == 5) {
    console.log("莅视_rslaser")
    globalConfigAll = globalConfigAll_elis_rslaser
}


let knowledgeTypeList = [
    { name: "知识分享", value: "5" },
    { name: "精彩术式", value: "1" },
    // { name: "行业资讯", value: "2" },
    { name: "产品资讯", value: "3" },
    { name: "前沿“镜”向", value: "4" },
    { name: "视频点播", value: "6" },
]

let videoPlayerHttp // pc端录播列表 播放视频 地址
switch (video_config) { // 1 本地17 2翼虎会议 3海泰正式环境 4测试（rslaser)
    case 1:
        videoPlayerHttp = "http://192.168.124.17:8099";
        break;
    case 2:
        videoPlayerHttp = 'http://47.98.181.27:3155'
        break;
    case 3:
        videoPlayerHttp = 'http://123.56.94.140:3155'
        break;
    case 4:
        videoPlayerHttp = 'http://8.141.169.141:3155'
        break;
}
export default {
    actSource,
    platFrom,
    platFromList,
    platFromList0,
    globalConfigAll,
    businessBelong,
    knowledgeTypeList,
    videoPlayerHttp
};
