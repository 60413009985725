const slideLibraryRoute = [
    // 玻片
    {
        path: 'home11',
        name: 'home11',
        component: () => import('../../views/viewsinlayout/role0/home0.vue')
    }, {
        path: 'SlideLibrary',
        name: 'SlideLibrary',
        component: () => import('../../views/viewsinlayout/microscope/SlideLibrary/slide/SlideLibrary.vue')
    },
    {//玻片讨论广场
        path: 'SlideDiscussionSquare',
        name: 'SlideDiscussionSquare',
        component: () => import('../../views/viewsinlayout/microscope/SlideLibrary/slide/SlideDiscussionSquare.vue')
    },
    {//与我相关
        path: 'RelatedToMe',
        name: 'RelatedToMe',
        component: () => import('../../views/viewsinlayout/microscope/SlideLibrary/RelatedToMe.vue')
    },
//     玻片详情
    {
        path: 'SlideDetail/:sliceDiscussId',
        name: 'SlideDetail',
        component: () => import('../../views/viewsinlayout/microscope/SlideLibrary/slide/SlideDetail.vue')
    },
    // 添加玻片
    {
        path: 'AddSlide',
        name: 'AddSlide',
        component: () => import('../../views/viewsinlayout/microscope/SlideLibrary/slide/AddSlide.vue')

    },
    {// 设备
        path: 'BlEquipment',
        name: 'BlEquipment',
        component: () => import('../../views/viewsinlayout/microscope/SlideLibrary/BlEquipment/BlEquipment.vue')
    },
    {// 设备 我的申请
        path: 'myApplicationBL',
        name: 'myApplicationBL',
        component: () => import('../../views/viewsinlayout/microscope/SlideLibrary/BlEquipment/myApplicationBL.vue')
    },
    {// 申请列表
        path: 'applicationTable',
        name: 'applicationTable',
        component: () => import('../../views/viewsinlayout/microscope/SlideLibrary/BlEquipment/applicationTable.vue')
    },
    {// 求助专家
        path: 'expertHelpZone',
        name: 'expertHelpZone',
        component: () => import('../../views/viewsinlayout/microscope/SlideLibrary/expertHelpZone.vue')
    },



]



export default slideLibraryRoute
