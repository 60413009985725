const personnel_management = [
    {// 人员管理-创新中心
        path: 'InnovationHome/:personType',
        name: 'InnovationHome',
        component: () => import('../../views/viewsinlayout/personnelManagement/InnovationCenter/InnovationHome.vue')
    },{// 人员管理-外科中心
        path: 'surgicalHome/:personType',
        name: 'surgicalHome',
        component: () => import('../../views/viewsinlayout/personnelManagement/surgicalCenter/surgicalHome.vue')
    },{// 人员管理-镜检中心
        path: 'microscopyHome/:personType',
        name: 'microscopyHome',
        component: () => import('../../views/viewsinlayout/personnelManagement/microscopyCenter/microscopyHome.vue')
    },
]



export default personnel_management
