<template>
  <div id="app1">
    <transition name="fade">
    <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      screenObj: {},
    };
  },
  components: {},
  created() {
    this.$router.beforeEach((to, from, next) => {
      // console.log(to);
      if (to.name == 'newcaseDiscussion') {
        localStorage.setItem('caseDiscussionTo', 'new');
      } else if (to.name == 'caseDetail') {
        localStorage.setItem('caseDiscussionTo', 'old');
      }
      next();
    });
  },
  mounted() {
    //   function  psize(){//解决最外层不被panel层撑开
    //     document.getElementById()
    //   }
    // var app1El = document.querySelector('#app1');
    // // console.log(app1El);
    // let widthScreen = window.screen.width;
    // // let heightScreen=window.screen.height;
    // let heightScreen = window.innerHeight;
    // // console.log(typeof(widthScreen),widthScreen);
    // let screenObj = {
    //   width: widthScreen,
    //   height: heightScreen,
    // };
    // this.screenObj = screenObj;
    // if (widthScreen > 1440) {
    //   // console.log(widthScreen,'widthScreen');
    //   // console.log(heightScreen,'heightScreen');
    //   app1El.style.width = widthScreen + 'px';
    //   app1El.style.height = heightScreen + 'px';
    // }
  },
  methods: {
    ceshi() {
      console.log('ceshi1');
    },
  },
};
</script>

<style scoped lang="less">
@d: 16;

/* @import"../iconfont/iconfont.css"; */
// yuan
// @import url('https://at.alicdn.com/t/font_3215411_zwmzqqsco9.css?spm=a313x.7781069.1998910419.49&file=font_3215411_zwmzqqsco9.css');
// bo
// @import url('https://at.alicdn.com/t/font_3112005_cr9j285v41r.css?spm=a313x.7781069.1998910419.78&file=font_3112005_cr9j285v41r.css');
// @import url('https://at.alicdn.com/t/font_3112005_itb4lm5u4ol.css');
@import url('https://at.alicdn.com/t/c/font_3112005_md6x0b7e24n.css');
@import url('https://at.alicdn.com/t/c/font_3215411_ioai3noivs.css');
// lin
// @import url('https://at.alicdn.com/t/font_3215411_6ouqxfgq9yq.css');
@import "./assets/css/inputCss/iview.css";


#app1 {
  // height: 900rem / @d;
  // width: 1440rem / @d;
  //  width:100vw; 
  //  height:100vh;
  //width:auto;
  //height:auto;
  // min-height:900px;
  //min-width:1440px;
  width: 100vw;
  height: 100vh;
  margin: 0;
  border:0;
  padding:0;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fade-enter-active {
  animation: fadeIn ease 1s;
}
.fade-leave-active {
  animation: fadeOut ease 0.4s;
}

</style>