import axios from 'axios';
// import { Message } from 'element-ui'
// import store from '@/store'
// import ElementUI from 'element-ui'
// import QS from 'qs'
// import * as auth from '@/utils/auth'
// // import router from '@/router'
import router from '../../router';
import { Modal } from 'view-design';
// Message,
import 'view-design/dist/styles/iview.css';
import store from './../../store';
// // const hostUrl =  'http://192.168.0.189:8888'

// // create an axios instance
// const service = axios.create({
//     // baseURL: hostUrl,
//     timeout: 50000 // request timeout
// })

// //请求头配置
// service.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

// // request interceptor
// service.interceptors.request.use(
//     config => {
//         //给请求头添加内容的话，从这里配置
//         // if (store.getters.token) {
//         //   config.headers['Authorization'] = getToken()
//         // }
//         return config
//     },
//     error => {
//         console.log(error) // for debug
//         return Promise.reject(error)
//     }
// )

// // response interceptor
// service.interceptors.response.use(
//     response => {
//         //未登录、登录过期code==40002
//         if (response.data.code == 40002) {
//             // store.dispatch('user/logout').then(() => {
//             //     location.reload()
//             // })
//             router.replace("/Login")
//         }

//         //其他错误，根据接口返回数据的规律调整
//         if (response.data.code != 200 && (response.data.code != 40002)) {
//             ElementUI.Message({
//                 type: 'error',
//                 message: response.data.message
//             })
//             return Promise.reject('error')
//         }

//         return Promise.resolve(response.data)
//     },

//     error => {
//         console.log('error', error);
//         Message({
//             message: '服务器内部错误',
//             type: 'error',
//             duration: 3 * 1000
//         })
//         return Promise.reject(error)
//     }
// )

// class http {
//     static async getPage(url, params) {
//         return service.get(url, { params: params })
//     }

//     static async post(url, params) {
//         return service.post(url, QS.stringify(params))
//     }
//     static async postItem(url, params) {
//         if (!params) {
//             params = {};
//         }
//         // params['userUuid']='9E396DE798B240FA8D2162BFE6AC494C';
//         // params['sessionId']='16e413064c97466c9bef6d9f9e69c5aa';

//         // 获取userUuid和sessionId
//         params['userUuid'] = auth.getAdminInfo().userUuid;
//         params['sessionId'] = auth.getAdminInfo().sessionId;
//         return service.post(url, params)
//     }

//     static async postNew(url, params) {
//         return service.post(url, params)
//     }

// }
// export default http
// axios.interceptors.request.use(config => {
//     //    console.log('请求拦截器：');
//     //    console.log(config)
//     //		插入的位置				我用本地储存存放获取到的用户token
//     if(JSON.parse(localStorage.getItem('vuex'))!=undefined){
//         config.headers.token = JSON.parse(localStorage.getItem('vuex')).userInfo.token;
//     }

//     return config
// })
// 响应拦截器
axios.interceptors.response.use(res => {
  // console.log('响应拦截器：', res);
  // if (res.headers.token) {
  //     var token = res.headers.token
  //     //      console.log(token)
  //     window.localStorage.setItem('user_token', token)
  // }
  if (res.data.code == 40002) {
    // Message.error("token信息有误,请重新登录");
    Modal.error({
      title: '提示',
      content: '您的账号在其他地方登录，您已被强制下线，请确认是否本人操作~',
      okText: '我知道了',
      onOk: () => {
        store.commit('quit');
        router.replace('/Login');
      }
    });
  } else if (res.data.code == 99999) {
    Modal.error({
      title: '提示',
      content: '哎呀，网络似乎不太稳定，请再试一次~',
      okText: '我知道了'
      // onOk:()=>{
      //     store.commit('quit');
      //     router.replace("/Login");
      // },
    });
  } else if (res.data.code == 10200) {
    Modal.error({
      title: '提示',
      content: '请求空指针,请联系后台工作人员进行处理~',
      okText: '我知道了'
      // onOk:()=>{
      //     store.commit('quit');
      //     router.replace("/Login");
      // },
    });
  }
  return res;
});
