import Vue from "vue";
import {Message} from "view-design"
var _this = Vue.prototype;
_this.$formatTime = function (fmt) {
    var o = {
        "M+": fmt.getMonth() + 1, //月份 
        "d+": fmt.getDate(), //日 
        "H+": fmt.getHours(), //小时 
        "m+": fmt.getMinutes(), //分 
        "s+": fmt.getSeconds(), //秒 
        "q+": Math.floor((fmt.getMonth() + 3) / 3), //季度 
        "S": fmt.getMilliseconds() //毫秒 
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (fmt.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
_this.$getNowFormatDate = function getNowFormatDate() {
    var date = new Date();
    var seperator1 = "-";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    let hour, minute, seconds;
    hour = date.getHours();
    minute = date.getMinutes();
    seconds = date.getSeconds();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
    }
    if (minute >= 0 && minute <= 9) {
        minute = "0" + minute;
    }
    if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hour + ":" + minute + ":" + seconds;
    return currentdate;
}
_this.$getFormatTime = function getNowFormatDate(date) {
    // var date = new Date();
    var seperator1 = "-";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    let hour, minute, seconds;
    hour = date.getHours();
    minute = date.getMinutes();
    seconds = date.getSeconds();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
    }
    if (minute >= 0 && minute <= 9) {
        minute = "0" + minute;
    }
    if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hour + ":" + minute + ":" + seconds;
    return currentdate;
}
_this.$sixAction = function (v) {
    console.log(v);
    let value = '';
    if (v.type == 'number') {
        if (v.value == "普外科") {
            value = 1;
        } else if (v.value == "妇科") {
            value = 2;
        } else if (v.value == "泌尿外科") {
            value = 3;
        } else if (v.value == "耳鼻咽喉科") {
            value = 4;
        } else if (v.value == "胸外科") {
            value = 5;
        } else if (v.value == "其他") {
            value = 6;
        }
    } else if (v.type == 'string') {
        if (v.value == "1") {
            value = '普外科';
        } else if (v.value == "2") {
            value = '妇科';
        } else if (v.value == "3") {
            value = '泌尿外科';
        } else if (v.value == "4") {
            value = '耳鼻咽喉科';
        } else if (v.value == "5") {
            value = '胸外科';
        } else if (v.value == "6") {
            value = '其他';
        }
    }
    return value;
}
_this.$getSubjectList = () => {
    let list = [
        { name: "普外科", id: 1 },
        { name: "妇科", id: 2 },
        { name: "泌尿外科", id: 3 },
        { name: "耳鼻咽喉科", id: 4 },
        { name: "胸外科", id: 5 },
        { name: "其他", id: 6 },
    ];
    return list;
}
_this.$timeNumber = (timer) => {
    // console.log(timer);
    var date = new Date(timer);
    var time1 = date.getTime();
    return time1
}
// 普外科 妇科 泌尿外科 耳鼻咽喉科 胸外科 其他

_this._Message = (res, success, error, time) => {
    if (res.data.resultFlag == true) {
        Message.success({
            content: success ? success : res.data.msg,
            duration: time ? time : 2
        });
    } else {
        Message.error({
            content: error ? error : res.data.msg,
            duration: time ? time : 2
        });
    }
}


// 时间格式化
_this.$getTime = (time,type) => {
    let format = type || '{y}-{m}-{d} {h}:{i}:{s}'
    const formatObj = {
        y: new Date(time).getFullYear(),
        m:  new Date(time).getMonth() + 1,
        d:  new Date(time).getDate(),
        h:  new Date(time).getHours(),
        i:  new Date(time).getMinutes(),
        s:  new Date(time).getSeconds()
      };
      const time_str = format.replace(/{(y|m|d|h|i|s)+}/g, (result, key) => {
        let value = formatObj[key];
        if (result.length > 0 && value < 10) {
          value = '0' + value;
        }
        return value || 0;
      });
        return time_str
}