const child6 = [
    {
        path: 'home6',
        name: 'home6',
        component: () => import('../../views/viewsinlayout/role1/home1.vue')

    },
]

export default {
    child6
}