import Vue from 'vue';
import {
    Button,
    Input,
    Radio,
    RadioGroup,
    Checkbox,
    Switch,
    Select,
    Option,
    Tabs,
    TabPane,
    Page,
    DatePicker,
    Upload,
    Message,
    Notice,
    Modal,
    Timeline,
    TimelineItem,
    InputNumber,
    Menu,
    MenuItem
    // ImagePreview

} from 'view-design';
// import 'view-design/dist/styles/iview.css';
Vue.component('Button',Button);
Vue.component('Input',Input);
Vue.component('Radio',Radio);
Vue.component('RadioGroup',RadioGroup);
Vue.component('Checkbox',Checkbox);
Vue.component('Switch',Switch);
Vue.component('Select',Select);
Vue.component('Option',Option);
Vue.component('Tabs',Tabs);
Vue.component('TabPane',TabPane);
Vue.component('Page',Page);
Vue.component('DatePicker',DatePicker);
Vue.component('Upload',Upload);
Vue.component('Message',Message);
Vue.component('Notice',Notice);
Vue.component('Modal',Modal);
Vue.component('Timeline',Timeline);
Vue.component('TimelineItem',TimelineItem);
Vue.component('InputNumber',InputNumber);

Vue.component('Menu',Menu);
Vue.component('MenuItem',MenuItem);
// Vue.component('ImagePreview',ImagePreview);

// Vue.component('iSwitch',iSwitch);


Vue.prototype.$Message = Message;
Vue.prototype.$Modal = Modal;
Vue.prototype.$Notice = Notice;


